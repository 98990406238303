
<template>
  <v-dialog
    overlay-color="primary"
    overlay-opacity=".97"
    v-model="bActive"
    max-width="73%">
    <v-card
      style="background-color: rgba(240, 240, 240, .85); padding: 5%">
      <v-container>
        <v-layout
          row
          justify-center>
          <p
            style="font-size: 20px !important; font-weight: normal !important"
            class="section_titles"
            v-text="'Editar un proyecto'"
          />
        </v-layout>
        <v-layout
          row
          wrap
          justify-center>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <span>
              <v-select
                :items="categories"
                append-icon="mdi-apps"
                background-color="white"
                label="Categoría"
                v-model="projectEdit.category"
                color="primary"
                outlined
                item-text="name"
                item-value="_id"
              />
            </span>
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-text-field
              append-icon="mdi-sign-text"
              background-color="white"
              label="Nombre"
              v-model="projectEdit.name"
              color="primary"
              outlined
            />
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-textarea
              append-icon="mdi-format-align-left"
              background-color="white"
              label="Descripción - Español"
              v-model="projectEdit.esDescription"
              color="primary"
              outlined
            />
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-textarea
              append-icon="mdi-format-align-left"
              background-color="white"
              label="Descripción - Inglés"
              v-model="projectEdit.enDescription"
              color="primary"
              outlined
            />
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-text-field
              append-icon="mdi-map-marker-circle"
              background-color="white"
              label="LAT"
              v-model="projectEdit.lat"
              color="primary"
              outlined
            />
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-text-field
              append-icon="mdi-map-marker-circle"
              background-color="white"
              label="LON"
              v-model="projectEdit.lon"
              color="primary"
              outlined
            />
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-text-field
              append-icon="mdi-play-box-outline"
              background-color="white"
              label="Video"
              v-model="projectEdit.video"
              color="primary"
              outlined
            />
          </v-flex>
          <v-flex
            class="flex_projects"
            xs12
            sm6>
            <v-text-field
              append-icon="mdi-nature-people"
              background-color="white"
              label="Disponibilidad"
              v-model="projectEdit.availability"
              color="primary"
              outlined
            />
          </v-flex>
        </v-layout>
        <v-layout
          row
          justify-center>
          <v-layout
            row
            justify-center
            style="max-width: 50%">
            <v-btn
              @click="close"
              large
              dark
              color="primary">
              Cancelar
            </v-btn>
            <v-spacer/>
            <v-btn
              :loading="load_confirm"
              :disabled="load_confirm"
              @click="confirm_edit"
              large
              dark
              color="primary">
              Confirmar
            </v-btn>
          </v-layout>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    load_confirm: false,
    load_confirm: false,
  }),
  props: {
    categories: Array,
    bActive: Boolean,
    projectEdit: Object,
    usr_token: String
  },
  methods: {
    confirm_edit () {
      this.load_confirm = true
/*    var body = new URLSearchParams()
      body.append('name', this.projectEdit.name)
      body.append('esDescription', this.projectEdit.esDescription)
      body.append('enDescription', this.projectEdit.enDescription)
      body.append('lat', parseFloat(this.projectEdit.lat))
      body.append('lon',  parseFloat(this.projectEdit.lon))
      body.append('video', this.projectEdit.video) */
      var project = {
        name: this.projectEdit.name,
        esDescription: this.projectEdit.esDescription,
        enDescription: this.projectEdit.enDescription,
        lat: parseFloat(this.projectEdit.lat),
        lon: parseFloat(this.projectEdit.lon),
        video: this.projectEdit.video,
        availability: this.projectEdit.availability
      }
      db.put(`${uri}/api/v1/projects/` + this.projectEdit._id, project, {
        headers: {
          'Authorization': this.usr_token
        }
      })
        .then((response) => {
          this.load_confirm = false
          this.$store.commit('toggle_alert', {color: 'success', text: "Se actualizó la información correctamente."})
          this.close()
        })
        .catch((error) => {
          this.load_confirm = false
          this.$store.commit('toggle_alert', {color: 'fail', text: error.response.data.message})
        })
    },
    update () {
      this.$emit('update')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
